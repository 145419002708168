<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useGameProgressExploreStore } from '~/store/progress/GameProgressExplore'
  import useGameCity from '~/composables/GameCity/GameCity'
  import { computed } from '#imports'
  import { UiIconId, UiIconSizeId } from '~/model/UiIcon/UiIcon'
  import useUiIcon from '~/composables/UiIcon/UiIcon'
  import useTranslation from '~/composables/Translation/Translation'
  import { useGameProgressStore } from '~/store/progress/GameProgress'
  import useRouteRecord from '~/composables/RouteRecord/RouteRecord'
  import { RouteRecordId } from '~/model/RouteRecord/RouteRecord'

  const gameProgressStore = useGameProgressStore()
  const gameExploreProgressStore = useGameProgressExploreStore()
  const { unlockedTrouvailleCount, remainingTotalTrouvailleCount, totalTrouvailleCount } =
    storeToRefs(gameProgressStore)
  const { isActiveMode } = storeToRefs(gameExploreProgressStore)
  const { t } = useTranslation()
  const { toRoute } = useRouteRecord()
  const { toGameCity } = useGameCity()
  const { findIcon } = useUiIcon()

  const iconBaseId = computed(() => findIcon({ id: UiIconId.IconCoreCyanNegCurrency }))
  const iconHoverId = computed(() => findIcon({ id: UiIconId.IconCoreWhiteNegCurrency }))

  const flagText = computed<string>(() => {
    if (remainingTotalTrouvailleCount.value > 0) {
      return t('display-currency.trouvailles-until-all-found', {
        amount: remainingTotalTrouvailleCount.value,
      })
    }

    return t('display-currency.trouvailles-all-found')
  })

  if (!isActiveMode.value) {
    throw new Error(`Wrong game mode detected!`)
  }
</script>

<template>
  <div class="c-display-currency-explore s-header-entry">
    <ClientOnly>
      <NuxtLink
        :to="toRoute({ name: RouteRecordId.GameCinemaStorage })"
        class="u-reset c-display-currency-explore__wrap s-header-entry__wrap s-header-entry__link"
      >
        <span class="c-display-currency-explore__text-flag">
          <span class="c-display-currency-explore__text-flag-text">
            {{ flagText }}
          </span>
        </span>
        <div class="s-header-entry__icon-box">
          <UiIcon
            :id="iconBaseId"
            :size="UiIconSizeId.Large"
            class="s-header-entry__icon s-header-entry__icon--base"
          />
          <UiIcon
            :id="iconHoverId"
            :size="UiIconSizeId.Large"
            class="s-header-entry__icon s-header-entry__icon--hover"
          />
        </div>
        <div class="s-header-entry__description">
          <template v-if="remainingTotalTrouvailleCount > 0">
            <span class="s-header-entry__description-numbers">
              {{ unlockedTrouvailleCount }}/{{ totalTrouvailleCount }}
            </span>
            {{ t('navigation-game.trouvailles') }}
          </template>
          <template v-else>
            {{ t('display-currency.trouvailles-all-found-short') }}
          </template>
        </div>
      </NuxtLink>
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/util/text-flag' as text-flag;

  .c-display-currency-explore__text-flag {
    @include text-flag.style;
    @include text-flag.base;
    color: col.$monochrome-white;
  }

  .c-display-currency-explore__text-flag-text {
    padding: 4px 8px 3px;
    background-color: col.$monochrome-black;
  }

  .c-display-currency-explore__wrap {
    @include utils.has-focus {
      .c-display-currency-explore__text-flag {
        @include text-flag.hover;
      }
    }
  }
</style>
